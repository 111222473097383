import * as React from "react";
import ChristmasListClient from "../clients/ChristmasListClient";
import {Nav} from "../clients/model/ChristmasList";

export interface Auth {
    authed?: boolean
    login: () => Promise<void>
    logout: () => Promise<void>
}

async function promise(christmasListClient: ChristmasListClient): Promise<boolean> {
    return await christmasListClient.isLoggedIn()
}

export default function useAuth(): Auth {
    const christmasListClient: ChristmasListClient = new ChristmasListClient();

    const [authed, setAuthed] = React.useState<boolean>();

    React.useEffect(() => {
        promise(christmasListClient).then((data: boolean) => {
            setAuthed(data)
        })
    }, []);

    return {
        authed,
        login() {
            return new Promise<void>((res) => {
                setAuthed(true);
                res();
            });
        },
        logout() {
            return new Promise<void>((res) => {
                setAuthed(false);
                res();
            });
        }
    };
}