import React, {ReactElement, useEffect, useState} from "react";
import ChristmasListClient from "../../clients/ChristmasListClient";
import {Group, Nav} from "../../clients/model/ChristmasList";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {Drawer, List, styled} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {Link} from "react-router-dom";
import {TOMCAT_WEBAPP_URL} from "../../config/Constants";
import Divider from "@mui/material/Divider";

const promise = async (): Promise<Nav> => {
    try {
        const client = new ChristmasListClient();
        let response = await client.getNav();
        console.log("ChristmasListClient API getNav response", response);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${drawerWidth}px`,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


function NavBar(props:any) {
    const [navModel, setNavModel] = useState<Nav>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        promise().then((data: Nav) => {
            setNavModel(data)
        })
    }, [])
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    let groupDropdownItems: ReactElement[] = [];
    let purchaseListDropdownItems: ReactElement[] = [];
    if (navModel) {
        const user = navModel.user;
        navModel.groups.forEach((group: Group) => {
            groupDropdownItems.push(
                <ListItem component='a' href={TOMCAT_WEBAPP_URL + "/list/view?userId=" + user.id + "&groupId=" + group.groupId} button
                          key={group.groupId}>
                    <ListItemIcon>
                        <MailIcon/>
                    </ListItemIcon>
                    <ListItemText primary={group.name}/>
                </ListItem>
            );

            purchaseListDropdownItems.push(
                <ListItem component='a' href={TOMCAT_WEBAPP_URL + "/purchase/summary?groupId=" + group.groupId} button key={group.groupId}>
                    <ListItemIcon>
                        <MailIcon/>
                    </ListItemIcon>
                    <ListItemText primary={group.name}/>
                </ListItem>
            );
        });
    }


    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            GiftKeeper
                        </Typography>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem component='a' href={`${TOMCAT_WEBAPP_URL}/user/info`}>My Profile</MenuItem>
                                <MenuItem component='a' href={`${TOMCAT_WEBAPP_URL}/logout`}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    <ListItem
                              key='groups'>
                        <ListItemText primary='Groups'/>
                    </ListItem>
                    {groupDropdownItems}
                </List>
                <Divider/>
                <List>
                    <ListItem
                        key='purchases'>
                        <ListItemText primary='Purchase Lists'/>
                    </ListItem>
                    {purchaseListDropdownItems}
                </List>

                <Divider/>
                <List>
                    <ListItem component={Link} to={'/groups'} button key='groups'>
                        <ListItemIcon>
                            <MailIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Back to Groups'}/>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <>{props.children}</>
            </Main>
        </>
    );
}

export default NavBar;