import React from 'react';
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import SummaryPage from './views/groups/SummaryPage.jsx';

import SignIn from './views/login/SignIn.js';
import ListPage from "./views/groups/ListPage";
import {Layout} from "./layout/Layout";
import PurchaseSummary from "./views/purchase/PurchaseSummary";
import RequireAuth from "./views/auth/RequireAuth";

var browserHistory = Router.browserHistory;


function App() {

    return (
        <Router history={browserHistory}>
            <Routes>
                <Route exact path="/login" element={<SignIn/>}/>
                <Route path="/*" element={
                    <RequireAuth>
                        <Routes>
                            <Route element={<Layout/>}>
                                <Route path="/groups" element={<ListPage/>}/>
                                <Route path="/group/:groupId" element={<SummaryPage/>}/>
                                <Route path="/purchase/:groupId" element={<PurchaseSummary/>}/>
                            </Route>
                        </Routes>
                    </RequireAuth>
                }/>
            </Routes>
        </Router>
    );
}

export default App;
