import React, {Component} from "react";
import ChristmasListClient from "../../clients/ChristmasListClient";
import {
    Link,
} from "react-router-dom";
import {
    Table,
    TableCell,
    TableBody,
    TableRow, Grid
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";


class SummaryPage extends Component {
    constructor(props) {
        super(props);
        this.groupId = window.location.pathname.split("/")[2];
        console.log("GroupId:" + this.groupId);
        this.state = {
            isLoading: true,
            groupSummary: {}
        };
    }

    async componentDidMount() {
        try {
            const client = new ChristmasListClient();
            let response = await client.getGroupsSummary(this.groupId);
            console.log("ChristmasListClient API getGroupsSummary response", response);
            this.setState(
                {
                    isLoading: false,
                    isFailed: response == null ? true : false,
                    groupMembers: response
                }
            );
        } catch (error) {
            this.setState({isLoading: false, isFailed: true});
            console.error(error);
        }
    }

    getBody() {
        if (this.state.isLoading) {
            return <Skeleton variant="rectangular" height={400}/>;
        }
        if (this.state.isFailed) {
            return "Failed state";
        }
        return <Table className="whitespace-pre">
            <TableBody>
                {this.state.groupMembers.users.map((groupMember, index) => (
                        <TableRow component={Link} to={"user/" + groupMember.id} key={index}>
                            <TableCell>
                                {groupMember.name}
                            </TableCell>
                        </TableRow>
                    )
                )}
            </TableBody>
        </Table>;
    }

    getTitle() {
        if (this.state.isLoading) {
            return <Skeleton variant="text"/>;
        }
        return this.state.groupMembers.name;
    }

    render() {
        const body = this.getBody();
        const title = this.getTitle();
        return (
            <Container>
                <Grid container direction='column' spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant='h2'>{title}</Typography>
                        <Typography>Click on someone to see their list</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {body}
                    </Grid>
                </Grid>
            </Container>
        );
    }
};


export default SummaryPage;
