export interface IAppConfig {
    tomcatWebAppUrl: string
    apiGatewayEndpoint: string
    reactWebAppUrl: string
}

const AppConfigs: Record<string, IAppConfig> = {
    Devo: {
        tomcatWebAppUrl: 'https://christmaslist.michaelsutton.net',
        apiGatewayEndpoint: 'https://api.devo.christmaslist.michaelsutton.net',
        reactWebAppUrl: 'https://website.devo.christmaslist.michaelsutton.net',
    },
    Prod: {
        tomcatWebAppUrl: 'https://christmaslist.michaelsutton.net',
        apiGatewayEndpoint: 'https://api.christmaslist.michaelsutton.net',
        reactWebAppUrl: 'https://website.christmaslist.michaelsutton.net',
    }
}
export const AppConfig = ['devo', 'local'].includes(process.env.REACT_APP_ENV || '') ? AppConfigs.Devo : AppConfigs.Prod;