import React, {Component} from "react";
import ChristmasListClient from "../../clients/ChristmasListClient";
import PurchaseListDisplay from "./PurchaseListDisplay";
import {Skeleton, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


class PurchaseSummary extends Component {
    constructor(props) {
        super(props);
        this.groupId = window.location.pathname.split("/")[2];
        console.log("GroupId:" + this.groupId);
        this.state = {
            isLoading: true,
            purchaseList: {},
            totalPrice: 0.00
        };
    }

    async componentDidMount() {
        try {
            const client = new ChristmasListClient();
            let response = await client.getPurchaseList(this.groupId);
            let totalPrice = 0.00;
            if (response) {
                console.log("ChristmasListClient API getPurchaseList response", response);
                totalPrice = response.reduce((total, purchaseList) => total.concat(purchaseList.items), [])
                    .map((item) => {
                        return item.price ? item.price : 0;
                    })
                    .reduce((acc, curr) => acc + curr);
                totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100
            }
            this.setState(
                {
                    isLoading: false,
                    isFailed: response == null,
                    purchaseList: response,
                    totalPrice: totalPrice
                }
            );
        } catch (error) {
            this.setState({isLoading: false, isFailed: true});
            console.error(error);
        }
    }

    render() {
        let purchaseLists = [];

        let totalPrice = <Skeleton variant="text" />;
        if (this.state.isLoading) {
            purchaseLists = <Skeleton variant="rectangular" height={400}/>;
        } else {
            purchaseLists = this.state.purchaseList.map((list) => {
                    let uniqueKey = list.user.id;
                    return (
                        <PurchaseListDisplay key={uniqueKey} user={list.user} items={list.items}/>
                    )
                }
            );
            totalPrice = `$${this.state.totalPrice}`;
        }
        let pageHeader = "Purchase Summary";
        let pageHeaderSummary = "View your purchases and reservations";

        return (
            <Container>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/groups">Groups</a></li>
                    <li className="breadcrumb-item active">Purchase Summary</li>
                </ol>

                <div className="page-header">
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Typography variant={'h2'}>{pageHeader}</Typography>
                            <Typography variant={'h4'}>{pageHeaderSummary}</Typography>
                        </Grid>
                        <Grid item
                              justify="center"
                              alignItems="center"
                              xs={10} sm={4}>
                            <Typography align={'right'} variant={'h4'}>Total</Typography>
                            <Typography align={'right'} variant={'h4'}>{totalPrice}</Typography>
                        </Grid>
                    </Grid>
                </div>
                {purchaseLists}
            </Container>
        );
    }
}


export default PurchaseSummary;
