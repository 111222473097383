import React, {Component} from "react";
import PurchasedItem from "./PurchasedItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {ExpandMore} from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {Item, User} from "../../clients/model/ChristmasList";

const itemSize = "col-4";
const priceSize = "col-3";
const statusSize = "col-5";

interface IProps {
    user: User
    items: Item[]
}

function PurchaseListDisplay(props: IProps) {

    let accordionId = props.user.id;
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id={"head-" + accordionId}
            >
                <Typography>{props.user.name}&#39;s Purchase List</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ul className="list-group">
                    <div className=" list-group-item">
                        <div className="row">
                            <div className={itemSize}>Item</div>
                            <div className={priceSize + " text-right"}>Price</div>
                            <div className={statusSize + " text-center"}>Status</div>
                        </div>
                    </div>
                </ul>
                {props.items.map((item) => (
                    <PurchasedItem key={item.itemId} item={item} accordianId={accordionId}/>
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

export default PurchaseListDisplay;
