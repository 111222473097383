import React, {useEffect, useState} from "react";
import {Card, CardActionArea, CardContent, Grid, Table, TableBody, TableCell, TableRow, Theme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Group} from "../../clients/model/ChristmasList";
import ChristmasListClient from "../../clients/ChristmasListClient";
import {TOMCAT_WEBAPP_URL} from "../../config/Constants";

interface InitialStateData {
    isLoading: boolean;
    isFailed: boolean;
    groups: Group[];
}

const promise = async (): Promise<InitialStateData> => {
    try {
        const client: ChristmasListClient = new ChristmasListClient();
        let response: Group[] = await client.getGroups();
        console.log("ChristmasListClient API getGroups response", response);
        return {
            isLoading: false,
            isFailed: response == null ? true : false,
            groups: response
        };
    } catch (error) {
        console.error(error);
        return {isLoading: false, isFailed: true, groups: []};
    }
};

const useStyles = makeStyles((theme: Theme) => ({
    pageContent: {
        padding: '30px',
    }
}));

function ListPage() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFailed, setIsFailed] = useState<boolean>(false);
    const [groups, setGroups] = useState<Group[]>([]);
    const [latestGroup, setLatestGroup] = useState<Group>();

    useEffect(() => {
        promise().then((data: InitialStateData) => {
            const reverseGroups = data.groups.reverse();
            setLatestGroup(reverseGroups[0])
            setGroups(reverseGroups.slice(1));
            setIsLoading(data.isLoading);
            setIsFailed(data.isFailed);
        });
    }, []);


    function getLatestCard() {
        return <Card sx={{maxWidth: 345}}>
            <CardActionArea component='a' href={`${TOMCAT_WEBAPP_URL}/group/view?id=${latestGroup?.groupId}`}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {latestGroup?.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    }

    const getBody = () => {
        if (isLoading) {
            return <Skeleton variant="rectangular" height={400}/>;
        }
        if (isFailed) {
            return "Failed state";
        }
        let latestCard = getLatestCard();
        return <Grid container spacing={3}>
            <Grid item xs={12}>
                {latestCard}
            </Grid>
            <Grid item xs={12}>
                <Typography>Additional Groups</Typography>
                <Table>
                    <TableBody>
                        {groups.map((group: Group, index: number) => (
                                <TableRow component='a' href={`${TOMCAT_WEBAPP_URL}/group/view?id=${group.groupId}`}
                                          key={index}>
                                    <TableCell>
                                        {group.name}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>;
    };

    const body = getBody();

    return (
        <div className={classes.pageContent}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h2'>Your Groups</Typography>
                    <Typography>Click on your group to see the members and their lists</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {body}
                </Grid>
            </Grid>
        </div>
    );
}


export default ListPage;
