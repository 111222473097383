import React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {makeStyles, useTheme} from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import {AcUnit} from "@mui/icons-material";
import Grid from "@mui/material/Grid";

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Gift Keeper
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    footer: {
        paddingTop: '40px',
        color:theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Footer() {

    const classes = useStyles();

    const theme = useTheme();
    return (
        <div className={classes.footer}>
            <Grid container direction='column' alignItems='center' alignContent='center'>
                <Grid item xs>
                    <Avatar sx={{bgcolor:theme.palette.secondary.main}}>
                        <AcUnit />
                    </Avatar>
                </Grid>
                <Grid item xs>
                    <Typography component="h1" variant="h5">
                        Created by Michael in Seattle
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Box mb={4} mt={8}>
                        <Copyright/>
                    </Box>
                </Grid>
            </Grid>
        </div>

    );
}
