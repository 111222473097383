//account: 4343-8174-3407 region: us-west-2
import {Group, PurchaseList} from "./model/ChristmasList";
import {AppConfig} from "../config/AppConfig";

const endpoint = AppConfig.apiGatewayEndpoint;


export default class ChristmasListClient {

    async isLoggedIn(): Promise<boolean> {
        try {
            const response = await fetch(endpoint + "/auth/isLoggedIn", {
                credentials: "include",
            });
            const isLoggedIn: boolean = await response.json();
            console.log("ChristmasListClient.isLoggedIn", isLoggedIn);
            return isLoggedIn;
        } catch (e) {
            console.error("ChristmasListClient.isLoggedIn", e)
            return false;
        }
    }

    async getGroups(): Promise<Group[]> {
        const response = await fetch(endpoint + "/groups", {
            credentials: "include",
        });
        return await response.json();
    }

    async getGroupsSummary(groupId: string) {
        const response = await fetch(endpoint + "/group/" + groupId, {
            credentials: "include",
        });
        return await response.json();
    }

    async getPurchaseList(groupId: string): Promise<PurchaseList[]> {
        const response = await fetch(endpoint + "/purchase/" + groupId, {
            credentials: "include",
        });
        return await response.json();
    }

    async setTransactionStatus(itemId: string, status: string): Promise<boolean> {
        return true;
    }

    async getNav() {
        const response = await fetch(endpoint + "/nav", {
            credentials: "include",
        });
        return await response.json();
    }
};
