import React, {Component} from "react";
import ChristmasListClient from "../../clients/ChristmasListClient";
import {Check, CreditCard, LocalShipping, ShoppingCart} from "@mui/icons-material";
import {Button} from "@mui/material";

const STATUSES = [
    {value: "RESERVED", icon: <ShoppingCart/>},
    {value: "PURCHASED", icon: <CreditCard/>},
    {value: "RECEIVED", icon: <LocalShipping/>},
    {value: "WRAPPED", icon: <Check/>}
];

class ReservationButtons extends Component {
    constructor(props) {
        super(props);
        this.itemId = props.itemId;
        this.state = {
            statusValue: props.status,
            disabled: false
        };
        this.changeStatus = this.changeStatus.bind(this);
    }

    createButton(statusValue, icon) {
        let variant = (statusValue === this.state.statusValue) ? 'contained' : 'text';

        const changeStatus = this.changeStatus;
        const itemId = this.itemId;
        const key = this.itemId + "-" + statusValue;
        const statusAwareChangeHandler = function (event) {
            event.preventDefault();
            changeStatus(itemId, statusValue);
        }

        return (
            <Button key={key} disabled={this.state.disabled} value={statusValue} id={"btn-" + key}
                   variant={variant} onClick={statusAwareChangeHandler}>
                {icon}
            </Button>
        );
    }

    updateStatus(statusValue) {
        this.setState({
            statusValue: statusValue
        });
    }

    async changeStatus(itemId, statusValue) {
        this.setState({disabled: true});

        // Clicking the selected icon will unselect the status marking the item as open
        if (statusValue === this.state.statusValue) {
            statusValue = "OPEN";
        }
        let response = await ChristmasListClient.setTransactionStatus(itemId, statusValue);
        if (!response || response.err) {
            console.log("[ERROR] when changing status to: " + statusValue + " for item " + itemId);
        } else {
            console.log("Changed status to: " + statusValue + " for item " + itemId);
            this.updateStatus(statusValue);
        }
        this.setState({disabled: false});
    }

    createButtons(status) {
        let buttons = [];
        for (status of STATUSES) {
            buttons.push(this.createButton(status.value, status.icon));
        }

        return buttons;
    }

    render() {
        return this.createButtons(this.status);
    }
}


export default ReservationButtons;
