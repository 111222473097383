import React from "react";
import NavBar from "../views/nav/NavBar";
import {Outlet} from "react-router-dom";
import Footer from "../views/Footer";
import {createTheme, ThemeProvider} from "@mui/material";
import ThemeOptions from "../config/Theme";

const theme = createTheme(ThemeOptions);

export function Layout() {

    return (
        <ThemeProvider theme={theme}>
            <NavBar>
                <Outlet/>
            </NavBar>
            <Footer/>
        </ThemeProvider>
    )
}
