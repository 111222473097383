const ThemeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#325e4c'
        },
        secondary: {
            main: '#b14360'
        },
    },
}

export default ThemeOptions;