import React, {ReactElement} from "react";
import {TOMCAT_WEBAPP_URL} from "../../config/Constants";
import useAuth from "../../hooks/useAuth";
import {Loading} from "./Loading";

export default function RequireAuth({children}: { children: ReactElement }) {
    const {authed} = useAuth();
    if (authed == undefined) {
        console.info("Set loading")
        return <Loading/>
    }
    if (authed) {
        return children;
    }
    console.error("Not logged in. Redirecting")
    window.location.href = TOMCAT_WEBAPP_URL + "/login";
    return null;
};