import React, {Component} from "react";
import ReservationButtons from "./ReservationButtons";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const priceSize = "col-3";
const statusSize = "col-5";

class PurchasedItem extends Component {
    constructor(props) {
        super(props);
        this.item = props.item;
        this.accordianId = props.accordianId;
    }

    render() {
        return (
            <li className="list-group-item">
                <Grid container>
                    <Grid item xs={4}>
                        <a href={"/item/view?id=" + this.item.id}>
                            {this.item.name}
                        </a>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align={'right'}>{this.item.price}</Typography>
                    </Grid>
                    <Grid item className={'text-right'} xs={5}>
                    <ReservationButtons status={this.item.transaction.status} itemId={this.item.id}/>
                    </Grid>
                </Grid>
            </li>
        )
    }
}


export default PurchasedItem;
